import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/components/blocks/form-block.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/components/blocks/hero.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/components/blocks/projects-block.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/node_modules/next/dist/shared/lib/lazy-dynamic/preload-chunks.js");
;
import(/* webpackMode: "eager", webpackExports: ["SimulatePreloadedQuery"] */ "/vercel/path0/node_modules/@apollo/client-react-streaming/dist/index.cc.js");
;
import(/* webpackMode: "eager", webpackExports: ["Accordion","AccordionItem","AccordionTrigger","AccordionContent"] */ "/vercel/path0/packages/ui/src/components/accordion.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AlternatingText"] */ "/vercel/path0/packages/ui/src/components/alternating-text.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/packages/ui/src/components/blocks/rich-content.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/src/components/card/popup-cards.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/packages/ui/src/components/card/post-card.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CodeBlock"] */ "/vercel/path0/packages/ui/src/components/code-block.tsx");
